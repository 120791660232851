import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './RpcStatus.css'

function APIStatus(props) {
return (
  <p>In Progress</p>
)
}

export default APIStatus

